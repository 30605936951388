<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
  >
    <ShopsAndSites
      v-if="tress.length != 0"
      style="margin-right: 10px"
      :list="tress"
      @getShopId="getID"
    ></ShopsAndSites>
    <el-select
      clearable
      v-model="pageInfo.shipmentStatus"
      style="width：150px;margin-right:10px"
      placeholder="请选择货件状态"
      @change="find"
    >
      <el-option
        :label="item.value"
        :value="item.id"
        v-for="item in shipStatusList"
        :key="item.id"
      ></el-option>
    </el-select>
    <el-button type="primary" icon="el-icon-search" @click="find"
      >查询</el-button
    >
    <el-button style="margin: 10px 10px" type="primary" @click="shangchuan"
      >上传计划</el-button
    >
    <!-- <el-dialog
            title="上传补货计划"
            :visible.sync="uploadBox"
            width="21%"
            :before-close="uploadClose">
            <div style="margin-top:-20px">
                <p>1、请使用亚马逊后台模板，或下载模板。</p>
                <p style="margin:10px 0">2、只可以单店铺上传，导入时请确定要导入的店铺。</p>
                <el-button style="width:100%;margin:10px 0" type="primary" plain class="el-icon-download">根据Excel要求和例子填写模板——下载模板</el-button>
                <el-upload
                class="upload-demo"
                drag
                action="https://jsonplaceholder.typicode.com/posts/"
                multiple
                style="width:100%">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
            </div>
            <div slot="footer">
                <el-button @click="uploadBox = false">取 消</el-button>
                <el-button type="primary" @click="uploadBox = false">确 定</el-button>
            </div>
        </el-dialog> -->
    <el-table
      :data="tableData"
      border
      cell-style="text-align:center"
      header-cell-style="text-align:center;background:#EBEEF5"
      style="width: 100%"
    >
      <el-table-column prop="shipmentStatusName" label="货件状态" width="width">
      </el-table-column>
      <el-table-column prop="shopName" label="店铺" width="width">
      </el-table-column>
      <el-table-column prop="shipmentId" label="货件编号" width="width">
      </el-table-column>
      <el-table-column prop="trackNo" label="追踪编号" width="width">
      </el-table-column>
      <el-table-column prop="mskus" label="MSKUs" width="width">
      </el-table-column>
      <el-table-column prop="shippedQuantity" label="已发货" width="width">
      </el-table-column>
      <el-table-column prop="receivedQuantity" label="已收到" width="width">
      </el-table-column>
      <el-table-column prop="destination" label="目的地" width="width">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="width">
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" width="width">
      </el-table-column>
      <el-table-column prop="prop" label="操作" width="width">
        <template slot-scope="scope">
          <el-button
            type="text"
            style="font-size: 15px"
            class="el-icon-document"
            @click="getDetail(scope.row.id)"
            >补货计划详情</el-button
          >
          <el-button
            type="text"
            style="font-size: 15px"
            @click="operate(scope.row.id)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center; margin: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[15, 20, 30, 40]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog :visible.sync="detailBox" width="60%" :before-close="Close">
      <div style="margin-top: -20px">
        <el-divider content-position="left">补货计划详情</el-divider>
      </div>
      <div class="detail" style="width: 700px; padding-left: 25px">
        <div>
          <span>货件名称：</span><span>{{ detailInfo.shipmentName }}</span>
        </div>
        <div>
          <span>货件编号：</span><span>{{ detailInfo.shipmentId }}</span>
        </div>
        <br />
        <div>
          <span>发货地址：</span>
          <div style="display: inline-block; vertical-align: top">
            <p>{{ detailInfo.addressName }}</p>
            <p>{{ detailInfo.addressLine }}</p>
            <p>{{ detailInfo.postalCode }}</p>
            <p>{{ detailInfo.countryCode }}</p>
          </div>
        </div>
        <div>
          <span>配送地址：</span>
          <div style="display: inline-block; vertical-align: top">
            <p>{{ detailInfo.addressNameS }}</p>
            <p>{{ detailInfo.addressLineS }}</p>
            <p>{{ detailInfo.postalCodeS }}</p>
            <p>{{ detailInfo.countryCodeS }}</p>
          </div>
        </div>
        <br />
        <div>
          <span>货件状态：</span
          ><span>{{ detailInfo.shipmentStatusName }}</span>
        </div>
        <div>
          <span>货件内商品：</span><span>{{ detailInfo.content }}</span>
        </div>
        <br />
        <div>
          <span>已创建：</span><span>{{ detailInfo.createTime }}</span>
        </div>
        <div>
          <span>运输费估算：</span><span>{{ detailInfo.transportFee }}</span>
        </div>
        <br />
        <div>
          <span>已更新：</span><span>{{ detailInfo.updateTime }}</span>
        </div>
        <div>
          <span>运输方式和承运人:</span
          ><span>{{ detailInfo.transportMsg }}</span>
        </div>
      </div>
      <div style="margin-top: 20px">
        <el-divider content-position="left">货件内商品</el-divider>
      </div>
      <el-table
        style="width：100%;font-size:12px"
        border
        :data="detailInfo.asinShipmentItemResList"
        cell-style="text-align:center"
        header-cell-style="text-align:center"
      >
        <el-table-column
          prop="commoditySku"
          label="SKU"
          fixed="left"
          width="200"
        >
        </el-table-column>
        <el-table-column prop="fnsku" label="FNSKU" width="200">
        </el-table-column>
        <el-table-column prop="asin" label="ASIN" width="100">
        </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="shippedQuantity" label="已发货" width="100">
        </el-table-column>
        <el-table-column prop="receivedQuantity" label="已收到" width="100">
        </el-table-column>
        <el-table-column prop="onWayQuantity" label="在途中" width="100">
        </el-table-column>
        <el-table-column prop="inCaseQuantity" label="有异常" width="100">
        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button @click="detailBox = false">取 消</el-button>
        <el-button type="primary" @click="detailBox = false">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 上传计划弹框 -->
    <el-dialog
      width="1140px"
      :title="title"
      :visible.sync="addVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="shipFromAddress"
        :inline="true"
        :model="shipFromAddress"
        class="demo-form-inline"
        label-position="left"
        label-width="80px"
      >
        <el-form-item label="店铺">
          <ShopsAndSites1
            v-if="tress1.length != 0"
            style="margin-right: 10px"
            :list="tress1"
            @getShopId="getIDs"
          ></ShopsAndSites1>
        </el-form-item>
        <el-form-item
          prop="labelPrepPreference"
          label="标签类型"
          :rules="[
            { required: true, message: '请选择标签类型', trigger: 'change' },
          ]"
        >
          <el-select
            v-model="shipFromAddress.labelPrepPreference"
            placeholder="请选择标签类型"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="货件名称"
          prop="shipmentName"
          :rules="[
            { required: true, message: '请输入货件名称', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="shipFromAddress.shipmentName"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item
          label="包装类型"
          prop="packageType"
          :rules="[
            { required: true, message: '请选择包装类型', trigger: 'blur' },
          ]"
        >
          <el-radio-group v-model="shipFromAddress.packageType">
            <el-radio :label="0">混装商品</el-radio>
            <el-radio :label="1">原厂包装商品</el-radio>
          </el-radio-group>
        </el-form-item>
        <div>
          <el-form-item
            prop="countryCode"
            label="发货地址"
            :rules="[
              { required: true, message: '请选择国家名称', trigger: 'change' },
            ]"
          >
            <el-select
            filterable 
              v-model="shipFromAddress.countryCode"
              placeholder="请选择国家名称"
            >
              <el-option
                v-for="item in countryList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="name"
            :rules="[
              { required: true, message: '请输入发货方名称', trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="shipFromAddress.name"
              placeholder="发货方名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="addressLine1"
            label=""
            :rules="[
              {
                required: true,
                message: '请输入街道详细地址',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              style="width: 531px"
              v-model="shipFromAddress.addressLine1"
              placeholder="街道详细地址"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item
          prop="city"
          label=""
          style="margin-left: 81px"
          :rules="[
            { required: true, message: '请输入城市名称', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="shipFromAddress.city"
            placeholder="城市"
          ></el-input> </el-form-item
        ><el-form-item
          prop="districtOrCounty"
          label=""
          :rules="[{ required: true, message: '请输入区', trigger: 'blur' }]"
        >
          <el-input
            v-model="shipFromAddress.districtOrCounty"
            placeholder="区"
          ></el-input> </el-form-item
        ><el-form-item
          prop="stateOrProvinceCode"
          label=""
          :rules="[
            { required: true, message: '请输入州/省/地区', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="shipFromAddress.stateOrProvinceCode"
            placeholder="州/省/地区"
          ></el-input> </el-form-item
        ><el-form-item
          prop="postalCode"
          label=""
          :rules="[
            { required: true, message: '请输入邮政编码', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="shipFromAddress.postalCode"
            placeholder="邮政编码"
          ></el-input> </el-form-item
        ><el-form-item
          prop="telephone"
          label=""
          :rules="[
            { required: true, message: '请输入电话号码', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="shipFromAddress.telephone"
            placeholder="电话号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            cols="72%"
            type="textarea"
            v-model="shipFromAddress.tips"
            :autosize="{ minRows: 5, maxRows: 5 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <h3>货件商品</h3>
      <div style="text-align: left; margin-top: 30px; margin-bottom: 20px">
        <el-button type="primary" @click="addgoods">添加商品</el-button>
        <el-button type="primary" @click="excel">Excel导入</el-button>
      </div>
      <el-table
        border
        :data="tableDatas"
        @sort-change="sortTable"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 10px"
        header-cell-style="background:#FAFAFA;text-align:center;font-size:14px"
      >
        <el-table-column
          label="商品SKU/FNSKU"
          align="center"
          prop="commoditySku"
          fixed
        >
        </el-table-column
        ><el-table-column label="标题" align="center" prop="title" fixed>
        </el-table-column
        ><el-table-column label="本地SKU" align="center" prop="asin" fixed>
        </el-table-column
        ><el-table-column label="申报数量" align="center" fixed>
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.quantity"
              placeholder=""
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template>
            <el-link
              style="margin-right: 40px"
              :underline="false"
              type="primary"
              @click="asyncEdit(index)"
              >移除</el-link
            >
          </template>
        </el-table-column>
      </el-table>

      <div style="text-align: right; margin-top: 30px">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="shenbao('')">申报</el-button>
      </div>
    </el-dialog>
    <!-- 申报弹框 -->
    <el-dialog
      width="800px"
      title="货件确认"
      :visible.sync="innerVisible"
      append-to-body
      :close-on-click-modal="false"
    >
      <h3>基本信息</h3>
      <div class="aaa" style="margin-top: 20px; margin-left: 80px">
        <div class="fn">
          <div class="lable">店铺</div>
          <div class="value">{{ this.shopsName }}</div>
        </div>
        <div class="fn">
          <div class="lable">标签类型:</div>
          <div class="value">{{ shipFromAddress.labelPrepPreference }}</div>
        </div>
        <div class="fn">
          <div class="lable">包装类型:</div>
          <div class="value">{{ this.packageType }}</div>
        </div>
      </div>

      <h3 style="margin-top: 14%">寄件信息</h3>
      <el-table
        border
        :data="tableDatass"
        @sort-change="sortTable"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 10px"
        header-cell-style="background:#FAFAFA;text-align:center;font-size:14px"
      >
        <el-table-column label="货件名称" align="center" prop="name" fixed
          >{{ shipFromAddress.shipmentName }} </el-table-column
        ><el-table-column label="MSKU数" align="center" prop="mskuNum" fixed>
        </el-table-column
        ><el-table-column label="商品总数" align="center" prop="totalNum" fixed>
        </el-table-column
        ><el-table-column
          label="配送地址"
          align="center"
          prop="DestinationFulfillmentCenterId"
          fixed
        >
        </el-table-column>

        <el-table-column label="商品详情" align="center">
          <template slot-scope="scope">
            <el-link
              style="margin-right: 40px"
              :underline="false"
              class="el-icon-document"
              type="primary"
              @click="lastTable"
            ></el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: right; margin-top: 30px">
        <el-button @click="innerVisible = false">取消申报</el-button>
        <el-button type="primary" @click="shenbaoConfirm">确认申报</el-button>
      </div>
    </el-dialog>
    <!-- 添加商品弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="matchingTitle"
      :visible.sync="matching"
      width="70%"
      :before-close="matchingClose"
    >
      <div>
        <span style="padding-left: 100px">商品</span>
        <el-select
          clearable
          v-model="getGoodsInfo.status"
          style="width: 180px; margin: 0px 10px 0 10px"
          placeholder="商品状态"
        >
          <el-option
            :label="item.value"
            :value="item.id"
            v-for="item in product"
            :key="item.id"
          ></el-option>
        </el-select>
        <el-input
          clearable
          v-model="searchByAsin"
          style="width: 300px; margin-right: 10px; margin-top: 2px"
          placeholder="请输入ASIN"
          class="input-with-select"
        >
          <el-select
            clearable
            @clear="clearType"
            style="width: 100px"
            v-model="selectAsinType"
            slot="prepend"
            placeholder="选择类型"
          >
            <el-option
              :label="item.value"
              :value="item.id"
              v-for="item in searchList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-input>
        <el-button type="primary" @click="searchGoods">查询</el-button>
        <div class="Box" v-loading="dialogLoading">
          <div>
            <el-button type="text" class="all" @click="pushAll"
              >添加全部</el-button
            >
            <div v-loading="dialogLoading">
              <div style="height: 450px; overflow: auto">
                <div
                  class="item"
                  v-for="(item, index) in goodsList"
                  :key="item.id"
                >
                  <div class="pic">
                    <img :src="item.imageUrl" alt="" width="100%" />
                  </div>
                  <div class="SKU">
                    <p>
                      <span class="state"
                        ><img
                          :src="item.marketPlaceImageUrl"
                          alt=""
                          width="100%" /></span
                      ><span>子：{{ item.asin }}</span>
                    </p>
                    <p>
                      <span>SKU：{{ item.commoditySku }}</span>
                    </p>
                  </div>
                  <div class="realizable">
                    <p>可售库存：{{ item.sellableQuantity }}</p>
                    <p>可售天数：{{ item.canSellDay }}</p>
                  </div>
                  <div class="price">
                    <p>售价：${{ item.sellPrice }}</p>
                  </div>
                  <div
                    style="
                      font-weight: blod;
                      font-size: 23px;
                      color: #409eff;
                      width: 50px;
                    "
                  >
                    <span
                      @click="toAdded(item, index)"
                      v-if="item.operation == 0"
                      class="el-icon-circle-plus-outline"
                    ></span>
                    <!-- <span
                      style="
                        color: grey;
                        font-size: 12px;
                        display: block;
                        width: 50px;
                      "
                      v-if="item.isMatched == 1"
                      >已匹配</span
                    > -->
                  </div>
                </div>
              </div>
              <div style="text-align: right; padding-top: 20px">
                <el-pagination
                  background
                  @current-change="changeCurrent"
                  :current-page.sync="getGoodsInfo.current"
                  layout="prev, pager, next"
                  :total="goodsTotal"
                >
                </el-pagination>
              </div>
            </div>
          </div>
          <div>
            <el-tabs v-model="added">
              <el-tab-pane :label="已添加" name="first"></el-tab-pane>
            </el-tabs>
            <el-button type="text" class="all" @click="removeAll"
              >删除全部</el-button
            >
            <div
              style="height: 450px; overflow: auto"
              v-loading="dialogLoading"
            >
              <template v-for="(item, index) in addedList">
                <div class="item" :key="item.id">
                  <div class="pic">
                    <img :src="item.imageUrl" alt="" width="100%" />
                  </div>
                  <div class="SKU">
                    <p>
                      <span class="state"
                        ><img
                          :src="item.marketPlaceImageUrl"
                          alt=""
                          width="100%" /></span
                      ><span>子：{{ item.asin }}</span>
                    </p>
                    <p>
                      <span>SKU：{{ item.commoditySku }}</span>
                    </p>
                  </div>
                  <div class="realizable">
                    <p>可售库存：{{ item.sellableQuantity }}</p>
                    <p>可售天数：{{ item.canSellDay }}</p>
                  </div>
                  <div class="price">
                    <p>售价：${{ item.sellPrice }}</p>
                  </div>
                  <div
                    v-if="item.yet != 1"
                    style="
                      font-weight: blod;
                      font-size: 23px;
                      color: #409eff;
                      width: 30px;
                    "
                  >
                    <span
                      @click="toGoods(item, index)"
                      class="el-icon-delete"
                    ></span>
                  </div>
                  <!-- <div
                    v-if="item.yet == 1"
                    style="color: #999999; font-size: 14px; width: 50px"
                  >
                    已存在
                  </div> -->
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="matchingClose">取 消</el-button>
        <el-button type="primary" @click="match">确 定</el-button>
      </div>
    </el-dialog>
    <!-- EXCEL弹窗 -->
    <el-dialog
      title="Excel导入"
      :visible.sync="inventoryImportant"
      width="25%"
      :before-close="inventoryImportantClose"
    >
      <div style="margin-top: -20px; line-height: 20px; font-size: 12px">
        <p>1.模板中的表头不可更改，不可删除</p>
        <p>2.导入的数据不要超过两百条</p>
        <el-button
          type="primary"
          @click="down"
          plain
          style="width: 100%; margin: 15px 0"
          >下载空白模板<i class="el-icon-download"></i
        ></el-button>
        <div style="text-align: center">
          <el-upload
            style="width: 100%"
            class="upload-demo"
            :headers="mytoken"
            :on-success="handleSuccess"
            :file-list="fileList"
            drag
            :action="action"
            multiple
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="inventoryImportantClose">取 消</el-button>
        <el-button type="primary" @click="inventoryImportantClose"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 编辑弹框 -->
    <el-dialog
      width="1140px"
      title="编辑货件"
      :visible.sync="addVisible1"
      :close-on-click-modal="false"
    >
      <el-form
        :rules="rules"
        :inline="true"
        :model="shipFromAddress"
        class="demo-form-inline"
        label-position="left"
        label-width="80px"
      >
        <el-form-item label="店铺">
          <el-input disabled v-model="shopName" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="标签类型">
          <el-select
            disabled
            v-model="shipFromAddress.labelType"
            placeholder="请选择标签类型"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="货件名称" prop="shipmentName">
          <el-input
            v-model="shipFromAddress.shipmentName"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="包装类型">
          <el-radio-group v-model="shipFromAddress.packageType">
            <el-radio disabled :label="0">混装商品</el-radio>
            <el-radio disabled :label="1">原厂包装商品</el-radio>
          </el-radio-group>
        </el-form-item>
        <div>
          <el-form-item label="发货地址">
            <el-select
              v-model="shipFromAddress.countryCode"
              placeholder="请选择国家名称"
              disabled
            >
              <el-option
                v-for="item in countryList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-input
              v-model="shipFromAddress.name"
              placeholder="发货方名称"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input
              style="width: 531px"
              v-model="shipFromAddress.addressLine1"
              placeholder="街道详细地址"
              disabled
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item label="" style="margin-left: 81px">
          <el-input
            v-model="shipFromAddress.city"
            placeholder="城市"
            disabled
          ></el-input> </el-form-item
        ><el-form-item label="">
          <el-input
            v-model="shipFromAddress.districtOrCounty"
            placeholder="区"
            disabled
          ></el-input> </el-form-item
        ><el-form-item label="">
          <el-input
            v-model="shipFromAddress.stateOrProvinceCode"
            placeholder="州/省/地区"
            disabled
          ></el-input> </el-form-item
        ><el-form-item label="">
          <el-input
            v-model="shipFromAddress.postalCode"
            placeholder="邮政编码"
            disabled
          ></el-input> </el-form-item
        ><el-form-item label="">
          <el-input
            v-model="shipFromAddress.telephone"
            placeholder="电话号码"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            cols="72%"
            type="textarea"
            v-model="shipFromAddress.tips"
            :autosize="{ minRows: 5, maxRows: 5 }"
            disabled
          ></el-input>
        </el-form-item>
      </el-form>
      <h3>货件商品</h3>
      <div style="text-align: left; margin-top: 30px; margin-bottom: 20px">
        <el-button disabled type="primary" @click="addgoods"
          >添加商品</el-button
        >
        <el-button disabled type="primary" @click="excel">Excel导入</el-button>
      </div>
      <el-table
        border
        :data="tableDatas"
        @sort-change="sortTable"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 10px"
        header-cell-style="background:#FAFAFA;text-align:center;font-size:14px"
      >
        <el-table-column
          label="商品SKU/FNSKU"
          align="center"
          prop="commoditySku"
          fixed
        >
        </el-table-column
        ><el-table-column label="标题" align="center" prop="title" fixed>
        </el-table-column
        ><el-table-column label="本地SKU" align="center" prop="asin" fixed>
        </el-table-column
        ><el-table-column label="申报数量" align="center" fixed>
          <template slot-scope="scope">
            <el-input v-model="scope.row.quantity" placeholder=""></el-input>
          </template>
        </el-table-column>
      </el-table>

      <div style="text-align: right; margin-top: 30px">
        <el-button @click="addVisible1 = false">取 消</el-button>

        <el-button type="primary" @click="queren">确认</el-button>
      </div>
    </el-dialog>
    <!-- 详情弹框 -->
    <el-dialog
      width="1140px"
      title="商品详情"
      :visible.sync="addVisible2"
      :close-on-click-modal="false"
    >
      <el-table
        border
        :data="lasttableDate"
        @sort-change="sortTable"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 10px"
        header-cell-style="background:#FAFAFA;text-align:center;font-size:14px"
      >
        <el-table-column
          label="FNSKU"
          align="center"
          prop="FulfillmentNetworkSKU"
          fixed
        >
        </el-table-column
        ><el-table-column label="SKU" align="center" prop="SellerSKU" fixed>
        </el-table-column
        ><el-table-column label="数量" align="center" prop="Quantity" fixed>
        </el-table-column>
      </el-table>

      <div style="text-align: right; margin-top: 30px">
        <el-button @click="addVisible2 = false">取 消</el-button>

        <el-button type="primary" @click="addVisible2 = false">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { stockListPage, stockPageInit } from "@/api/CommodityManagement.js";
import {
  createInboundShipmentPlan,
  addProduct,
  createPlanInit,
  addShipmentFile,
  createInboundShipment,
  updateInboundShipment,
  edit,
} from "@/api/procurementCenter.js";
import { listPage, pageInits, detail } from "@/api/supplyCenter.js";
import ShopsAndSites from "@/components/ShopsAndSites.vue";
import ShopsAndSites1 from "@/components/ShopsAndSites.vue";

export default {
  components: {
    ShopsAndSites,
    ShopsAndSites1,
  },
  data() {
    var mytoken = window.sessionStorage.getItem("token");
    return {
      lasttableDate: [],
      addVisible2: false,
      shopName: "",
      addVisible1: false,
      mytoken: { token: mytoken },
      labelPrepPreference: "",
      goodsLists: [],
      fileList: [],
      inventoryImportant: false,
      goodsTotal: "",
      typeList: [],
      quantity: "",
      tress: [],
      obj: [],
      tableDatas: [],
      tableDatass: [],
      goodsList: [],
      inputList: "asin",
      product: [],
      searchList: [],
      searchByAsin: "",
      selectAsinType: "",
      getGoodsInfo: {
        status: "",
        current: "",
      },
      matching: false,
      title: "",
      addVisible: false,
      innerVisible: false,
      list: [],
      countryList: [],
      addedList: [],
      shipFromAddress: {
        shipmentName: "",
        name: "",
        telephone: "",
        countryCode: "",
        stateOrProvinceCode: "",
        districtOrCounty: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        packageType: "",
        postalCode: "",
        tips: "",
        labelPrepPreference: "",
      },
      packageType: "",
      shopsName: "",
      allInformation: {},
      loading: false,
      tableData: [],
      pageInfo: {
        current: "1",
        pageSize: "15",
        shopId: "",
        marketPlaceId: "",
        shipmentStatus: "",
      },
      total: "",
      detailBox: false,
      uploadBox: false,
      shipStatusList: [],
      dialogLoading: false,
      detailInfo: {}, //详情信息
      inner: [],
      oneInner: [],
      pageInfoList: {
        shopID: "",
        marketplaceId: "",
      },
      rowId: "",
    };
  },
  created() {
    this.getListPage();
    this.getPageInit();
    this.getPageInits();
    this.getData2();
  },
  computed: {
    action() {
      return process.env.VUE_APP_API_URL + "/api/AsinShipment/addShipmentFile";
    },
  },
  methods: {
    clearType() {
      this.searchByAsin = "";
    },
    lastTable() {
      this.addVisible2 = true;
    },
    searchGoods() {
      this.getGoodsInfo.current = 1;
      this.getStockListPage();
    },
    getPageInits() {
      stockPageInit().then((res) => {
        this.product = res.data.data.productStatusList;
        this.searchList = res.data.data.searchList;
      });
    },
    //编辑确认
    async getData5() {
      console.log(this.inner);
      let ooo = {
        shipmentName: this.shipFromAddress.shipmentName,
        // strArray: this.strArray,
        id: this.rowId,
        marketPlaceId: this.pageInfo.marketPlaceId,
        shopId: this.pageInfo.shopId,
        inboundShipmentPlanRequestItems: this.inner,
      };
      try {
        const res = await updateInboundShipment(ooo);
        if ((Object.is(res.code), 200)) {
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.addVisible1 = false;
          } else {
            this.addVisible1 = true;
            this.$message.error(res.data.message);
          }
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //确认申报
    async getData4() {
      let shenInformation = {
        marketPlaceId: this.pageInfoList.marketPlaceId,
        shopId: this.pageInfoList.shopId,
        shipmentName: this.shipFromAddress.shipmentName,
        strArray: this.strArray,
      };
      try {
        const res = await createInboundShipment(shenInformation);
        if ((Object.is(res.code), 200)) {
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.getListPage();
          } else {
            this.$message.error(res.data.message);
          }
        } else {
          this.$message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //编辑按钮
    operate(id) {
      this.rowId = id;
      this.loading = true;
      this.addVisible1 = true;
      edit(id).then((res) => {
        this.loading = false;
        if (res.data.code == 200) {
          this.shipFromAddress = res.data.data;
          // this.tableDatas = res.data.data.itemList.reverse();
          this.tableDatas = res.data.data.itemList
          this.pageInfoList.shopID = res.data.data.shopId;
          this.pageInfoList.marketplaceId = res.data.data.marketPlaceId;
          let names = "";
          let outId = this.tress.find((e) => {
            if (e.id === this.pageInfoList.shopID) {
              let innId = this.tress.forEach((i) => {
                i.marketPlaceTree.find((j) => {
                  if (j.id === this.pageInfoList.marketplaceId) {
                    return (names = e.name + "-" + j.name);
                  } else {
                    return false;
                  }
                });
              });
            } else {
              return false;
            }
          });
          this.shopName = names;
        } else {
          this.dialogLoading = false;
          this.this.addVisible1 = false;
          this.$message.warning(res.data.message);
        }
      });
    },
    //申报按钮
    async getData() {
      this.loading = true;
      let allInformation = {
        marketplaceId: this.pageInfoList.marketPlaceId,
        shopId: this.pageInfoList.shopId,
        shipFromAddress: this.shipFromAddress,
        labelPrepPreference: this.labelPrepPreference,
        inboundShipmentPlanRequestItems: this.oneInner,
      };
      try {
        const res = await createInboundShipmentPlan(allInformation);
        if ((Object.is(res.code), 200)) {
          if (res.data.code == 200) {
            this.$message.success(res.data.message);
            this.loading = false;
            this.tableDatass = res.data.data;
            this.lasttableDate = res.data.data[0].Items;
            this.strArray = this.tableDatass;
            this.innerVisible = true;
          } else {
            this.loading = false;
            this.$message.error(res.data.message);
          }
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getData1() {
      try {
        const res = await addProduct({ ...this.getGoodsInfo });
        if ((Object.is(res.code), 200)) {
          this.goodsList = res.data.values.map((item) => {
            item.operation = 0;
            return item;
          });
          this.goodsTotal = res.data.pageInfo.total;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getData2() {
      try {
        const res = await createPlanInit();
        if ((Object.is(res.code), 200)) {
          this.countryList = res.data.data.countryCodeList;
          this.typeList = res.data.data.labelTypeList;
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getData3() {
      try {
        const res = await addShipmentFile();
        if ((Object.is(res.code), 200)) {
          console.log(res.data);
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    getStockListPage() {
      this.dialogLoading = true;
      stockListPage({
        ...this.getGoodsInfo,
        [this.selectAsinType]: this.searchByAsin ? this.searchByAsin : null,
      }).then((res) => {
        this.dialogLoading = false;
        console.log(res);
        this.goodsList = res.data.values.map((item) => {
          item.operation = 0;
          return item;
        });
        this.goodsTotal = res.data.pageInfo.total;
        if (this.addedList.length == 0) return;
        this.goodsList.forEach((item, index) => {
          this.addedList.forEach((i) => {
            if (item.id == i.id) {
              this.goodsList[index].operation = 1;
              this.goodsList[index].yet = 1;
            }
          });
        });
      });
    },
    changeCurrent(val) {
      this.getGoodsInfo.current = val;
      // this.getData1();
      this.getStockListPage()

    },
    //申报
    shenbao() {
      this.$refs.shipFromAddress.validate((valid) => {
        // console.log(valid);
        if (valid) {
          this.oneInner = this.tableDatas.map((x) => {
            return {
              condition: "NewItem",
              sellerSKU: x.commoditySku,
              quantity: x.quantity,
              asin: x.asin,
              quantityInCase: 0,
            };
          });
          this.getData();

          if (this.shipFromAddress.packageType == 0) {
            this.packageType = "混装商品";
          } else {
            this.packageType = "原厂包装商品";
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //编辑确认
    queren() {
      this.inner = this.tableDatas.map((x) => {
        return {
          sellerSKU: x.commoditySku,
          quantity: x.quantity,
          asin: x.asin,
          quantityInCase: 0,
          condition: "NewItem",
        };
      });
      console.log(this.inner);

      this.getData5();
    },
    addgoods() {
      this.matching = true;
      this.getData1();
      this.getStockListPage();
    },
    shenbaoConfirm() {
      this.getData4();
      this.addVisible = false;
      this.innerVisible = false;
    },
    match() {
      this.matching = false;
      this.tableDatas = this.addedList;
    },
    toAdded(item, index) {
      this.goodsList[index].operation = 1;
      this.addedList.unshift(this.goodsList[index]);
    },
    pushAll() {
      this.goodsList.forEach((item, index) => {
        if (item.isMatched != 1) {
          this.goodsList[index].operation = 1;
          this.addedList.push(this.goodsList[index]);
        }
      });
    },
    removeAll() {
      const arr = [];
      this.addedList.forEach((item, index) => {
        if (item.yet == 1) {
          arr.push(item);
        }
      });
      this.addedList = arr;
      this.goodsList.forEach((item, index) => {
        if (item.isMatched == 1) {
          this.goodsList[index].operation = 1;
        } else {
          this.goodsList[index].operation = 0;
        }
      });
    },
    toGoods(item, index) {
      this.goodsList.forEach((i, ind) => {
        if (i.id == item.id) {
          this.goodsList[ind].operation = 0;
        }
      });
      this.addedList.splice(index, 1);
    },
    asyncEdit(index) {
      this.tableDatas.splice(index, 1);
    },
    excel() {
      this.inventoryImportant = true;
    },
    down() {
      window.location.href = "货件商品模板.xlsx";
    },
    handleSuccess(res, file, fileList) {
      console.log(res, file, fileList);
      if (res.code == 200) {
        console.log(res.data);
        this.tableDatas = res.data;
        this.$message.success(res.message);
        this.fileList = fileList;
      } else {
        this.$message.error(res.message);
        this.fileList = fileList.filter((item) => {
          return item.response.code == 200;
        });
      }
    },
    inventoryImportantClose() {
      this.inventoryImportant = false;
      this.fileList = [];
    },
    shangchuan() {
      // console.log("shangchuan", this.pageInfo.shopId);
      this.addedList = [];
      this.packageType = "";
      this.tableDatas = [];
      // this.pageInfo.shopId = "";
      // this.pageInfo.marketPlaceId = "";
      this.labelPrepPreference = "";
      this.shipFromAddress = {
        shipmentName: "",
        name: "",
        telephone: "",
        countryCode: "",
        stateOrProvinceCode: "",
        districtOrCounty: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        packageType: "",
        postalCode: "",
        tips: "",
      };
      this.title = "创建货件";
      this.addVisible = true;
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getListPage();
    },
    handleCurrentChange(val) {
      this.pageInfo.current = val;
      this.getListPage();
    },
    getListPage() {
      this.loading = true;
      listPage(this.pageInfo).then((res) => {
        this.loading = false;
        this.tableData = res.data.values;
        this.total = res.data.pageInfo.total;
      });
    },
    getPageInit() {
      pageInits().then((res) => {
        this.shipStatusList = res.data.data.shipStatusList;
        this.tress = res.data.data.tress;
        this.tress1 = res.data.data.tress;
        this.tress.forEach((item) => {
          item.marketPlaceTree.forEach((i) => {
            if (i.isSelected == 1) {
              this.pageInfo.shopId = item.id;
              this.pageInfo.marketPlaceId = i.id;
            }
          });
        });
        this.tress1.forEach((item) => {
            item.marketPlaceTree.forEach((i) => {
              if (i.isSelected == 1) {
                this.pageInfo.shopId = item.id;
                this.pageInfo.marketPlaceId = i.id;
              }
            });
          });
        this.getListPage();
      });
    },

    getID(shopID, marketPlaceId) {
      this.pageInfo.shopId = shopID;
      this.pageInfo.marketPlaceId = marketPlaceId;
      this.getListPage()

    },
    getIDs(shopID, marketPlaceId, name) {
      this.pageInfoList.shopId = shopID;
      this.pageInfoList.marketPlaceId = marketPlaceId;
      this.shopsName = name;

    },
    clear() {
      this.pageInfo.shopId = "";
      this.pageInfo.marketPlaceId = "";
    },
    find() {
      this.pageInfo.current = 1;
      this.getListPage();
    },
    getDetail(id) {
      this.loading = true;
      detail(id).then((res) => {
        this.detailBox = true;
        this.loading = false;
        if (res.data.code == 200) {
          this.detailInfo = res.data.data;
        } else {
          this.dialogLoading = false;
          this.this.detailBox = false;
          this.$message.warning(res.data.message);
        }
      });
    },
    Close() {
      this.detailBox = false;
      this.detailInfo = {};
    },
  },
};
</script>

<style scoped lang='scss'>
.detail {
  // font-size: 13px;
  p {
    margin-bottom: 10px;
  }
  > div {
    width: 350px;
    display: inline-block;
    margin-bottom: 15px;
    > span:nth-of-type(1) {
      margin-right: 4px;
      font-weight: 600;
    }
  }
}
.item {
  height: 85px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  .pic {
    width: 70px;
    height: 70px;
    margin-right: 5px;
    overflow: hidden;
  }
  .SKU {
    height: 50px;
    width: 150px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .state {
    display: inline-block;
    width: 24px;
    height: 16px;
    vertical-align: middle;
    margin-top: -4px;
  }
  .realizable {
    width: 120px;
    height: 50px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .price {
    width: 120px;
    height: 60px;
    p {
      line-height: 60px;
    }
  }
}

.fn {
  float: left;
  text-align: center;
  height: 80px;
  width: 200px;
}
.lable {
  width: 60px;
  height: 38px;
  float: left;
  line-height: 40px;
}
.value {
  width: 140px;
  height: 40px;
  float: left;
  line-height: 40px;
  background-color: #fff;
  text-align: left;
  color: #666666ff;
}
.Box {
  display: flex;
  margin: 20px 40px;
  > div {
    overflow: auto;
    flex: 1;
    border: 1px solid #f0f0f0;
    padding: 10px;
    position: relative;
    > .all {
      position: absolute;
      top: 10px;
      right: 44px;
    }
  }
}
</style>